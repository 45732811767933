import React, {useEffect, useState} from "react";
import {Button, Card, Col, DatePicker, PageHeader, Row, Select, Statistic} from "antd";
import { useQuery } from "react-query";
import api from "../api";
import StatisticsPerPaymentMethodTable from "./StatisticsPerPaymentMethodTable";
import StatisticsPerVendingMachineTable from "./StatisticsPerVendingMachineTable";
import { FormattedMessage, useIntl } from "react-intl";
import KeycloakRoles from "../components/KeycloakRoles";
import StatisticPerPosType from "./StatisticPerPosType";
import moment from "moment";


const { Option } = Select;
const { RangePicker } = DatePicker;

type MerchaneSelectProps = {
  onChange(mid: any): void;
};

type CurrencySelectProps = {
  onChange(currency: any): void;
}

const MerchantSelect: React.FC<MerchaneSelectProps> = ({ onChange }) => {
  const { data: merchants, isLoading } = useQuery("merchants", () => api.merchants.findAll());

  return (
    <Select
      showSearch
      style={{ width: 320, marginRight: 10 }}
      placeholder={<FormattedMessage id="transaction.statistics.selectMerchant" />}
      optionFilterProp="children"
      onChange={(mid) => onChange(mid)}
      loading={isLoading}
      allowClear
    >
      {merchants
        ?.sort((a, b) => a.name.localeCompare(b.name))
        .map((merchant) => (
          <Option value={merchant.mid} key={merchant.mid}>
            {merchant.name}
          </Option>
        ))}

    </Select>
  );
};

const CurrencySelect: React.FC<CurrencySelectProps> = ({ onChange }) => {
  const { data: currencies, isLoading } = useQuery("currencies", () => api.merchants.fetchCurrencies());

  return (
    <Select
      showSearch
      style={{ width: 160 }}
      placeholder={<FormattedMessage id="transaction.statistics.selectCurrency" />}
      optionFilterProp="children"
      onChange={(currency) => onChange(currency)}
      defaultValue={"EUR"}
      loading={isLoading}
      allowClear
    >
      {currencies
        ?.sort((a:string, b:string) => a.localeCompare(b))
        .map((currency:string) => (
          <Option value={currency} key={currency}>
            {currency}
          </Option>
        ))}
    </Select>
  );
};

const TransactionStatistics = () => {
  const [selectedMerchant, setSelectedMerchant] = useState("");
  const [selectedCurrency, setSelectedCurrency] = useState("EUR");
  const [startDate, setStartDate] = useState<any>();
  const [endDate, setEndDate] = useState<any>();
  const today = new Date().toISOString().split("T")[0];




  // const url = URL.createObjectURL(blob);


  const intl = useIntl();

  const {data: statisticTotalData} = useQuery(
    ["statistic-total", selectedMerchant, startDate, endDate, selectedCurrency],
    () =>
      api.payments.findStatisticTotalById(
        selectedMerchant,
        startDate?.format("YYYY-MM-DDT00:00"),
        endDate?.format("YYYY-MM-DDT00:00"),
        selectedCurrency
      ),
    {
      enabled: [selectedMerchant, startDate, endDate, selectedCurrency],
    }
  );
  const {data: statisticData} = useQuery(
    ["statistic", selectedMerchant, startDate, endDate, selectedCurrency],
    () =>
      api.payments.findStatisticById(
        selectedMerchant,
        startDate?.format("YYYY-MM-DDT00:00"),
        endDate?.format("YYYY-MM-DDT00:00"),
        selectedCurrency
      ),
    {
      enabled: [selectedMerchant, startDate, endDate, selectedCurrency],
    }
  );

  const {data: statisticAll} = useQuery(
    ["statisticAll", startDate, endDate, selectedCurrency],
    () => api.payments.findStatisticAll(startDate?.format("YYYY-MM-DDT00:00"), endDate?.format("YYYY-MM-DDT00:00"), selectedCurrency),
    {enabled: [startDate, endDate, selectedCurrency]}
  );

  const {data: statisticAllVmTable} = useQuery(
    ["statisticAllVmTable", startDate, endDate, selectedCurrency],
    () =>
      api.payments.findStatisticAllVmTable(startDate?.format("YYYY-MM-DDT00:00"), endDate?.format("YYYY-MM-DDT00:00"), selectedCurrency),
    {
      enabled: [startDate, endDate, selectedCurrency],
    }
  );

  let url: string = "";
  let blob: BlobPart;
  const [loading, setLoading] = useState(false)
  const fetchPosts = async () => {
    const res = await api.payments.getCsv(startDate?.format("YYYY-MM-DDT00:00"), endDate?.format("YYYY-MM-DDT23:59:59"));
     blob = new Blob([res], { type: "text/csv;charset=utf-8" });

  };


  useEffect(() => {
    if(loading) {
      fetchPosts().then(() => getData());
    }
  }, [loading]);


  function getData() {
    if(loading) {
      url = URL.createObjectURL(blob);
      let link = document.createElement("a");
      link.download = today + "_payment_statistics_allMerchants.csv";
      link.href = url;
      document.body.appendChild(link);
      link.click();
      //window.location.assign(url);
    }
    setLoading(!loading)
  }

  const start = startDate ? startDate : moment();
  const end = endDate ? endDate : moment().subtract(1, 'months');

    return (
      <>
        <PageHeader
          title={<FormattedMessage id="sidebar.statistics"/>}
          extra={[
            <MerchantSelect key={1} onChange={setSelectedMerchant}/>,
            <CurrencySelect key={2} onChange={setSelectedCurrency}/>,

            <RangePicker
              value={[start, end]}
              onChange={(values) => {
                if (values && values[0] && values[1]) {
                  setStartDate(values[0]);
                  setEndDate(values[1]);
                } else {
                  setStartDate(undefined);
                  setEndDate(undefined);
                }
              }}
            />

          ]}
        >
          <Row gutter={16}>
            <Col xs={24} lg={8}>
              <Card>
                <Statistic
                  title={<FormattedMessage id="vendingmachines.title"/>}
                  value={
                    statisticData?.content != null
                      ? statisticData?.content.length
                      : (selectedMerchant === undefined || selectedMerchant === "") && KeycloakRoles()?.includes("admin")
                        ? statisticAllVmTable?.content?.length
                        : 0
                  }
                />
              </Card>
            </Col>
            <Col xs={24} lg={8}>
              <Card>
                <Statistic
                  title={<FormattedMessage id="sidebar.transactions"/>}
                  value={
                    (selectedMerchant === undefined || selectedMerchant === "") && KeycloakRoles()?.includes("admin")
                      ? statisticAll?.content[0]?.paymentCount
                      : statisticTotalData && statisticTotalData[0]?.paymentCount
                  }
                />
              </Card>
            </Col>
            <Col xs={24} lg={8}>
              <Card>
                <Statistic
                  title={intl.formatMessage({id: "transaction.history.table.revenue"})}
                  value={
                    selectedCurrency !== "" && selectedCurrency !== undefined
                      ?
                      `${new Intl.NumberFormat(intl.locale, {
                        style: "currency",
                        currency: `${statisticTotalData && statisticTotalData[0]
                          ? statisticTotalData[0]?.currency
                          : statisticAll?.content[0]?.currency ?? "EUR"

                        }`,
                      }).format(
                        statisticTotalData && statisticTotalData[0]
                          ? (selectedMerchant === undefined || selectedMerchant === "") && KeycloakRoles()?.includes("admin")
                            ? statisticAll?.content[0]?.sumAmount / 100
                            : statisticTotalData[0]?.sumAmount / 100
                          : (selectedMerchant === undefined || selectedMerchant === "") && KeycloakRoles()?.includes("admin")
                            ? statisticAll?.content[0]?.sumAmount / 100
                            : 0
                      )}`
                      : intl.formatMessage({id: "transaction.history.table.multipleCurrencies"})
                  }
                  precision={2}
                />
              </Card>
            </Col>
          </Row>
        </PageHeader>
        <div style={{margin: "16px 19px 0"}}>
          <Row gutter={{xs: 1, sm: 16, md: 24, lg: 32}}>
            <Col sm={15} xs={24} lg={11} md={20}>
              <StatisticPerPosType
                merchant={selectedMerchant}
                from={startDate?.format("YYYY-MM-DDT00:00")}
                until={endDate?.format("YYYY-MM-DDT00:00")}
                isAdmin={KeycloakRoles()?.includes("admin")}
                currency={selectedCurrency}
              />
            </Col>
            <Col sm={15} xs={24} lg={13} md={20}>
              <StatisticsPerVendingMachineTable
                merchant={selectedMerchant}
                from={startDate?.format("YYYY-MM-DDT00:00")}
                until={endDate?.format("YYYY-MM-DDT00:00")}
                currency={selectedCurrency}
              />
            </Col>
            <Col sm={15} xs={24} lg={11} md={20}>
              <StatisticsPerPaymentMethodTable
                merchant={selectedMerchant}
                from={startDate?.format("YYYY-MM-DDT00:00")}
                until={endDate?.format("YYYY-MM-DDT00:00")}
                currency={selectedCurrency}
              />
            </Col>
          </Row>
        </div>
        {KeycloakRoles()?.includes("admin" || KeycloakRoles()?.includes("ZIIB_Sachbearbeiter")) ? (
          <div style={{margin: "24px 24px 0"}}>
            <Button onClick={()=>setLoading(!loading)}>
                <FormattedMessage id="statistics.downloadAll" />
            </Button>

          </div>

        ) : null}
      </>
    );
  };

export default TransactionStatistics;
