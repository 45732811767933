/* eslint-disable react/style-prop-object */
import React, { useEffect, useState } from "react";
import { useQuery, queryCache } from "react-query";
import { Button, PageHeader, Tag, Select, DatePicker, Tooltip, Row } from "antd";
import { Table } from "ant-table-extensions";
import { FormattedDate, FormattedNumber, FormattedMessage, useIntl } from "react-intl";
import api from "../api";
import { CloudDownloadOutlined } from "@ant-design/icons";
import KeycloakRoles from "../components/KeycloakRoles";
import keycloak from "../keycloak";
import { Link } from "react-router-dom";
import CustomPaginationNav from "../components/CustomPaginationNav";
import moment from "moment/moment";
import momentTimezone from "moment-timezone";

const { RangePicker } = DatePicker;

const { Option } = Select;
type MerchaneSelectProps = {
  onChange(mid: any): void;
};

const MerchantSelect: React.FC<MerchaneSelectProps> = ({ onChange }) => {
  const { data: merchants, isLoading } = useQuery("merchants", () => api.merchants.findAll());

  return (
    <Select
      showSearch
      style={{ width: 320 }}
      placeholder={<FormattedMessage id="transaction.statistics.selectMerchant" />}
      optionFilterProp="children"
      onChange={(mid) => {
        onChange(mid);
        console.log(mid);
      }}
      loading={isLoading}
      allowClear
    >
      {merchants
        ?.sort((a, b) => a.name.localeCompare(b.name))
        .map((merchant) => (
          <Option value={merchant.mid} key={merchant.mid}>
            {merchant.name}
          </Option>
        ))}
    </Select>
  );
};

const colorMap: { [char: string]: string } = {
  SNACK_DRINKS: "orange",
  TOBACCO: "grey",
  DRINKS: "grey",
  TAXI: "yellow",
  COFFEE: "brown",
  DONATION: "purple",
};

const Transactions = () => {
  const [transacSort, setTransacSort] = useState(localStorage.getItem("transacSort") || "issuedAt");
  const [transacDirection, setTransacDirection] = useState(localStorage.getItem("transacDirection") || "asc");
  function getDirection(type: any) {
    return type === "" ? "asc" : type === "descend" ? "desc" : "asc";
  }

  const columns = [
    {
      title: <FormattedMessage id="transaction.history.table.statisticId" />,
      dataIndex: "transactionId",
      render: (record: string) => record?.substr(0, 8),
      sorter: (a: any, b: any, type: any) => {
        setTransacSort("transactionId");
        setTransacDirection(getDirection(type));
        return a.transactionId?.localeCompare(b.transactionId);
      }
    },
    {
      title: <FormattedMessage id="transaction.history.table.approval" />,
      render: (record: any) => (
        <FormattedDate
          value={new Date(record.issuedAt)}
          year={"numeric"}
          month={"2-digit"}
          day={"2-digit"}
          hour={"numeric"}
          minute={"numeric"}
        />
      ),
      sorter: (a: any, b: any, type: any) => {
        setTransacSort("issuedAt");
        setTransacDirection(getDirection(type));
        return a?.issuedAt.localeCompare(b?.issuedAt);
      }
    },
    {
      title: <FormattedMessage id="transaction.history.table.approvalTimezone" />,
      render: (record: any) => (
        <FormattedDate
          value={momentTimezone.utc(record.issuedAt).format()}
          year={"numeric"}
          month={"2-digit"}
          day={"2-digit"}
          hour={"numeric"}
          minute={"numeric"}
          timeZone={record?.vendingMachine?.merchant?.timezone}
        />
      ),
      sorter: (a: any, b: any, type: any) => {
        setTransacSort("issuedAt");
        setTransacDirection(getDirection(type));
        return a?.issuedAt.localeCompare(b?.issuedAt);
      }
    },
    {
      title: <FormattedMessage id="vendingmachines.title" />,
      render: (record: any) => record?.vendingMachine?.serialNumber,
      sorter: (a: any, b: any, type: any) => {
        setTransacSort("vendingMachine.serialNumber");
        setTransacDirection(getDirection(type));
        return a.vendingMachine?.serialNumber?.localeCompare(b.vendingMachine?.serialNumber);
      }
    },
    {
      title: <FormattedMessage id="transaction.history.table.posType" />,
      render: (record: any) => (
        <Tag color={colorMap[record?.vendingMachine?.vendingMachineType.type]}>
          {record?.vendingMachine?.vendingMachineType.description}
        </Tag>
      ),
      sorter: (a: any, b: any, type: any) => {
        setTransacSort("vendingMachine.vendingMachineType.description");
        setTransacDirection(getDirection(type));
        return a.vendingMachine?.vendingMachineType?.description?.localeCompare(
          b.vendingMachine?.vendingMachineType?.description);
      }
    },
    {
      title: <FormattedMessage id="vendingmachines.table.column.merchant" />,
      dataIndex: "vendingMachine",
      render: (record: VendingMachine) => record?.merchant?.name,
      sorter: (a: any, b: any, type: any) => {
        setTransacSort("vendingMachine.merchant.name");
        setTransacDirection(getDirection(type));
        return a.vendingMachine?.merchant?.name?.localeCompare(b.vendingMachine?.merchant?.name);
      }
    },
    {
      title: <FormattedMessage id="transaction.history.table.revenue" />,
      render: (record: any) => (
        <FormattedNumber value={record.amount / 100} style="currency" currency={record.currency} />
      ),
      align: "right" as "right",
      sorter: (a: any, b: any, type: any) => {
        setTransacSort("amount");
        setTransacDirection(getDirection(type));
        return a?.amount - b?.amount;
      }
    },
    {
      title: <FormattedMessage id="transaction.history.table.provider" />,
      render: (record: any) =>
        record.amount < 0 ? <></> : <FormattedMessage id={"payment.method." + record.paymentType} />,
      sorter: (a: any, b: any, type: any) => {
        setTransacSort("paymentType");
        setTransacDirection(getDirection(type));
        return a?.paymentType?.localeCompare(b?.paymentType);
      }
    },
    {
      title: <FormattedMessage id="transaction.history.table.ccBrand" />,
      render: (record: any) => (
        record.amount < 0 ? <></> : (record.ccBrand === "Unknown" ? <FormattedMessage id="transaction.failed.unknown.ccBrand" /> : record.ccBrand)
      ),
      sorter: (a: any, b: any, type: any) => {
        setTransacSort("ccBrand");
        setTransacDirection(getDirection(type));
        return a?.ccBrand?.localeCompare(b?.ccBrand);
      }
    },
    {
      title: <FormattedMessage id="transaction.history.table.compartment" />,
      render: (record: any) =>
        record.receipt !== undefined ? (
          record.receipt.details.some((pair: any) => pair["key"] === "Fachnummer") ? (
            record.receipt.details.filter((pair: any) => pair["key"] === "Fachnummer")[0].value === "" ? (
              <FormattedMessage id="transaction.history.table.compartment.no.value" />
            ) : (
              record.receipt.details.filter((pair: any) => pair["key"] === "Fachnummer")[0].value
            )
          ) : (
            <FormattedMessage id="transaction.history.table.compartment.no.value" />
          )
        ) : (
          <FormattedMessage id="transaction.history.table.compartment.no.value" />
        ),
    },
    {
      title: <FormattedMessage id="transaction.history.table.cancel" />,
      className: "cancel",
      render: (record: any) =>
        !record.canceled ? (
          <>
            <Tooltip title="Cancel">
              <Link to={""}>
                <Button type="text" onClick={() => cancelTransaction(record.transactionId)}>
                  <FormattedMessage id="transaction.history.table.cancel" />
                </Button>
              </Link>
            </Tooltip>
          </>
        ) : //canceled color line
        record.amount < 0 ? (
          <div style={{ color: "red" }}>
            <FormattedMessage id="transaction.history.table.cancelation" />
          </div>
        ) : (
          <div style={{ color: "red" }}>
            <FormattedMessage id="transaction.history.table.canceled" />
          </div>
        ),
    },
  ];

  const cancelTransaction = async (transactionId: string) => {
    await api.payments.cancelTransaction(transactionId);
    setTriggerRefetchingAfterCancelation(!triggerRefetchingAfterCancelation);
  };

  var num_pageSize: number = +(sessionStorage.getItem("pageIndex") || 0);
  var num_entriesSize: number = +(sessionStorage.getItem("entriesSize") || 10);
  const [pagination, setPagination] = useState({ index: num_pageSize, entries: num_entriesSize });
  const [pageSize, setPageSize] = useState<number>(100);
  let authToken: any = keycloak.idTokenParsed ? keycloak.idTokenParsed : false;
  const intl = useIntl();
  const [merchantId, setMerchantId] = useState(KeycloakRoles()?.includes("merchant_admin") ? authToken.mid : "");
  const [startDate, setStartDate] = useState<any>();
  const [endDate, setEndDate] = useState<any>();
  const [data, setData] = useState<Payment[]>([]);
  const [isDataLoading, setIsDataLoading] = useState<boolean>(true);
  const [triggerRefetchingAfterCancelation, setTriggerRefetchingAfterCancelation] = useState<boolean>(true);

  var csvData = intl.formatMessage({ id: "csv.header" });

  var merchantCsvData = intl.formatMessage({ id: "csv.header" });

  useEffect(() => {
    if (!merchantId) {
      setStartDate(undefined);
      setEndDate(undefined);
    }
    setPagination({ index: 0, entries: num_entriesSize });
  }, [merchantId]);

  useEffect(() => {
    setIsDataLoading(true);
    api.payments
      .findAll(pagination.entries, pagination.index, transacSort, transacDirection,
        merchantId === undefined ? "" : merchantId, startDate?.format("YYYY-MM-DDT00:00"), endDate?.format("YYYY-MM-DDT00:00"))
      .then((res) => {
        setData(res);
        setIsDataLoading(false);
      });
    api.payments
      .size(merchantId === undefined ? "" : merchantId, startDate?.format("YYYY-MM-DDT00:00"), endDate?.format("YYYY-MM-DDT00:00"))
      .then((res) => setPageSize(res));
    localStorage.setItem("transacSort", transacSort);
    localStorage.setItem("transacDirection", transacDirection);
  }, [merchantId, startDate, endDate, pagination, triggerRefetchingAfterCancelation, transacSort, transacDirection]);

  var {
    data: merchantCsv,
    isSuccess: successfulQuery,
    isLoading: isLoadingMerchant,
  } = useQuery(
    [merchantId, startDate, endDate, triggerRefetchingAfterCancelation],
    () =>
      api.payments.findAllById(merchantId, startDate?.format("YYYY-MM-DDT00:00"), endDate?.format("YYYY-MM-DDT00:00")),
    { enabled: merchantId === "" ? false : true || [startDate, endDate] }
  );

  //CSV Build
  if (typeof merchantCsv !== "string") {
    merchantCsv?.forEach(
      ({ vendingMachine, transactionId, amount, paymentType, issuedAt, transactionSuccessful, currency, ccBrand }, idx) => {
        merchantCsvData += [
          (idx + 1).toString(),
          vendingMachine?.merchant?.mid,
          vendingMachine?.merchant?.name,
          vendingMachine?.serialNumber,
          vendingMachine?.vendingMachineType?.description,
          (vendingMachine?.address ?? "") +
            (vendingMachine?.postalcode ? " , " + vendingMachine?.postalcode : "") +
            (vendingMachine?.city ? " , " + vendingMachine?.city : ""),
          transactionId,
          paymentType,
          ccBrand,
          amount,
          issuedAt.replace("T", " "),
          transactionSuccessful ? intl.formatMessage({ id: "common.yes" }) : intl.formatMessage({ id: "common.no" }),
          currency,
        ].join(";");
        merchantCsvData += "\n";
      }
    );
  }

  var merchantCsvUrl = URL.createObjectURL(new Blob(["\uFEFF" + merchantCsvData], { type: "text/csv;charset=utf-8" }));

  const blob = new Blob(["\uFEFF" + csvData], { type: "text/csv;charset=utf-8" });
  const link = document.createElement("a");
  const url = URL.createObjectURL(blob);


  const downloadTransactions = async () => {
    const csv = await queryCache.fetchQuery(
      ["csv", startDate, endDate, triggerRefetchingAfterCancelation],
      () => api.payments.findAllList(startDate?.format("YYYY-MM-DDT00:00"), endDate?.format("YYYY-MM-DDT00:00")),
      { enabled: [startDate, endDate] }
    );
    csv?.forEach(
      ({ vendingMachine, transactionId, amount, paymentType, issuedAt, transactionSuccessful, currency, ccBrand }, idx) => {
        csvData += [
          (idx + 1).toString(),
          vendingMachine?.merchant?.mid,
          vendingMachine?.merchant?.name,
          vendingMachine?.serialNumber,
          vendingMachine?.vendingMachineType?.description,
          (vendingMachine?.address ?? "") +
            (vendingMachine?.postalcode ? " , " + vendingMachine?.postalcode : "") +
            (vendingMachine?.city ? " , " + vendingMachine?.city : ""),
          transactionId,
          paymentType,
          ccBrand,
          amount,
          issuedAt.replace("T", " "),
          transactionSuccessful ? intl.formatMessage({ id: "common.yes" }) : intl.formatMessage({ id: "common.no" }),
          currency,
        ].join(";");
        csvData += "\n";
      }
    );
    const link = document.createElement("a");
    document.body.appendChild(link);
    link.style.display = "none";
    const blob = new Blob([csvData], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    link.href = url;
    link.download = "payment_statistics_merchant.csv";
    link.click();
  };

  const start = startDate ? startDate : moment();
  const end = endDate ? endDate : moment().subtract(1, 'months');

  return (
    <>
      {KeycloakRoles()?.includes("admin") || KeycloakRoles()?.includes("ZIIB_Sachbearbeiter") ? (
        <PageHeader
          title={<FormattedMessage id="sidebar.transactions" />}
          extra={[
            <MerchantSelect key={1} onChange={setMerchantId} />,
            <RangePicker
              key={2}
              value={[start, end]}
              onChange={(values) => {
                setPagination({ index: 0, entries: num_entriesSize });
                if (values && values[0] && values[1]) {
                  setStartDate(values[0]);
                  setEndDate(values[1]);
                }
              }}
            />,
          ]}
        />
      ) : (
        <PageHeader title={<FormattedMessage id="sidebar.transactions" />} />
      )}
      <div style={{ margin: "16px 24px 0" }}>
        <Table
          rowClassName={(record, index) => (record.canceled ? "ant-text-grey" : "")}
          pagination={false}
          loading={isDataLoading}
          onChange={ () => {
            setPagination({ index: 0, entries: num_entriesSize })
          }}
          dataSource={data}
          columns={
            KeycloakRoles()?.includes("admin") || KeycloakRoles()?.includes("ZIIB_Sachbearbeiter")
              ? columns
              : columns.filter((col) => col.className !== "cancel")
          }
          rowKey={(record) => record.transactionId}
        />
        <Row justify="end">
          <b style={{ margin: 10 }}>
            {pagination.index * pagination.entries}-{pagination.index * pagination.entries + data.length}{" "}
            {intl.formatMessage({
              id: "antd.table.pagination.of",
            })}{" "}
            {pageSize}{" "}
            {intl.formatMessage({
              id: "antd.table.pagination.items",
            })}
          </b>

          <CustomPaginationNav
            index={pagination.index}
            pageSize={pageSize}
            index_entries_datalength={pagination.index * pagination.entries + data.length}
            entries={Number(sessionStorage.getItem("entriesSize")) || 10}
            onPrev={() => {
              setPagination({ index: pagination.index - 1, entries: pagination.entries });
              sessionStorage.setItem("pageIndex", `${pagination.index - 1}`);
            }}
            onNext={() => {
              setPagination({ index: pagination.index + 1, entries: pagination.entries });
              sessionStorage.setItem("pageIndex", `${pagination.index + 1}`);
            }}
            onSelect={(value) => {
              sessionStorage.setItem("entriesSize", `${value}`);
              setPagination({ index: pagination.index, entries: Number(value) });
            }}
          />
        </Row>
        <Button
          style={{ alignContent: "space-between" }}
          icon={<CloudDownloadOutlined />}
          onClick={downloadTransactions}
        >
          {" "}
          {successfulQuery && typeof merchantCsv !== "string" ? (
            <a href={merchantCsvUrl} download={"payment_statistics_merchant.csv"} onClick={link.click}>
              <FormattedMessage id="csv.downloadMerchant" />
            </a>
          ) : isLoadingMerchant ? (
            <FormattedMessage id="csv.merchantDataLoading" />
          ) : (
            <FormattedMessage id="csv.download" />
          )}
        </Button>
      </div>
    </>
  );
};

export default Transactions;
