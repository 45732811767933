/* eslint-disable react/style-prop-object */
import React, { useEffect, useState } from "react";
import { usePaginatedQuery, useQuery } from "react-query";
import { PageHeader, Select } from "antd";
import { TablePaginationConfig } from "antd/lib/table";
import { Table } from "ant-table-extensions";
import { FormattedDate, FormattedNumber, FormattedMessage } from "react-intl";
import api from "../api";
import KeycloakRoles from "../components/KeycloakRoles";
import keycloak from "../keycloak";

const { Option } = Select;
type MerchaneSelectProps = {
  onChange(mid: any): void;
};

const MerchantSelect: React.FC<MerchaneSelectProps> = ({ onChange }) => {
  const { data: merchants, isLoading } = useQuery("merchants", () => api.merchants.findAll());

  return (
    <Select
      showSearch
      style={{ width: 320 }}
      placeholder={<FormattedMessage id="transaction.statistics.selectMerchant" />}
      optionFilterProp="children"
      onChange={(mid) => {
        onChange(mid);
        console.log(mid);
      }}
      loading={isLoading}
      allowClear
    >
      {merchants
        ?.sort((a, b) => a.name.localeCompare(b.name))
        .map((merchant) => (
          <Option value={merchant.mid} key={merchant.mid}>
            {merchant.name}
          </Option>
        ))}
    </Select>
  );
};

const colorMap: { [char: string]: string } = {
  SNACK_DRINKS: "orange",
  TOBACCO: "grey",
  DRINKS: "grey",
  TAXI: "yellow",
  COFFEE: "brown",
  DONATION: "purple",
};

//// Titel Tabelle
const columns = [
  /* {
         title: <FormattedMessage id="transaction.diagnosis.table.statisticId" />,
         dataIndex: "transactionId",
         render: (record: string) => record?.substr(0, 8),
         sorter: (a: any, b: any) => a.transactionId?.localeCompare(b.transactionId),
     },*/
  {
    title: <FormattedMessage id="transaction.diagnosis.table.timestamp" />,
    render: (record: any) => (
      <FormattedDate
        value={new Date(record.issuedAt)}
        year={"numeric"}
        month={"2-digit"}
        day={"2-digit"}
        hour={"numeric"}
        minute={"numeric"}
      />
    ),
    sorter: (a: any, b: any) => a?.issuedAt.localeCompare(b?.issuedAt),
  },
  {
    title: <FormattedMessage id="transaction.diagnosis.table.vendimgmachineTitle" />,
    render: (record: any) => record?.vendingMachine?.serialNumber,
    sorter: (a: any, b: any) => a.vendingMachine?.serialNumber?.localeCompare(b.vendingMachine?.serialNumber),
  },
  {
    title: <FormattedMessage id="transaction.diagnosis.table.merchant" />,
    dataIndex: "vendingMachine",
    render: (record: VendingMachine) => record?.merchant?.name,
    sorter: (a: any, b: any) => a.vendingMachine?.merchant?.name?.localeCompare(b.vendingMachine?.merchant?.name),
  },
  {
    title: <FormattedMessage id="transaction.diagnosis.table.amount" />,
    render: (record: any) => (
      <FormattedNumber
        value={record.price / 100}
        style="currency"
        currency={record?.vendingMachine?.merchant?.currency?.toString()}
      />
    ),
    align: "right" as "right",
    sorter: (a: any, b: any) => a?.price - b?.price,
  },
  {
    title: <FormattedMessage id="transaction.diagnosis.table.errorMessage" />,
    render: (record: any) => record.errorMessage,
    sorter: (a: any, b: any) => a?.errorMessage?.localeCompare(b?.errorMessage),
  },
];

const Diagnosis = () => {
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    pageSize: 10,
    current: 1,
  });
  let authToken: any = keycloak.idTokenParsed ? keycloak.idTokenParsed : false;

  const [merchantId, setMerchantId] = useState(KeycloakRoles()?.includes("merchant_admin") ? authToken.mid : "");
  const [startDate, setStartDate] = useState<any>();
  const [endDate, setEndDate] = useState<any>();

  useEffect(() => {
    if (!merchantId) {
      setStartDate(undefined);
      setEndDate(undefined);
    }
  }, [merchantId]);

  //Queries
  const { data: findAll } = useQuery(["errors", startDate, endDate], () => api.diagnosis.findAll(merchantId));
  var { data: errorsupdated } = useQuery(["errors", merchantId], () => api.diagnosis.findAll(merchantId), {
    enabled: merchantId,
  });

  const fetchErrors = async (key: any, page = 0, pageSize = 10) => {
    const response = api.diagnosis.findAll(merchantId);
    return await response;
  };

  const { isLoading, latestData } = usePaginatedQuery(["errors", pagination.current, pagination.pageSize], fetchErrors);

  const handleTableChange = (pagination: TablePaginationConfig, filters: any, sorter: any) => {
    setPagination(pagination);
  };

  return (
    <>
      {KeycloakRoles()?.includes("admin") || KeycloakRoles()?.includes("ZIIB_Sachbearbeiter") ? (
        <PageHeader
          title={<FormattedMessage id="sidebar.diagnosis" />}
          extra={[
            <MerchantSelect key={1} onChange={setMerchantId} />,
            /* <RangePicker
                             key={2}
                             value={[startDate, endDate]}
                             onChange={(values) => {
                                 if (values && values[0] && values[1]) {
                                     setStartDate(values[0]);
                                     setEndDate(values[1]);
                                 } else {
                                     setStartDate(undefined);
                                     setEndDate(undefined);
                                 }
                             }}
                         />,*/
          ]}
        />
      ) : (
        <PageHeader title={<FormattedMessage id="sidebar.diagnosis" />} />
      )}
      <div style={{ margin: "16px 24px 0" }}>
        <Table
          pagination={pagination}
          loading={isLoading || latestData === undefined}
          dataSource={errorsupdated?.content === undefined ? findAll?.content : errorsupdated?.content}
          columns={columns}
          rowKey={(record) => record.transactionId}
          onChange={handleTableChange}
        />
      </div>
    </>
  );
};

export default Diagnosis;
