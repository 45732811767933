import { ExclamationCircleOutlined, LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Input,
  InputNumber,
  message,
  Row,
  Select,
  Steps,
  Switch,
  Typography,
  Modal,
} from "antd";
import moment from "moment";
import React, { useEffect, useState, useReducer } from "react";
import { FormattedMessage, useIntl, FormattedNumber } from "react-intl";
import { queryCache, useQuery } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import api from "../api";
import { Boop } from "../components/Boop";
import Condition from "../components/Condition";
import ImageUpload from "../components/ImageUpload";
import KeycloakRoles from "../components/KeycloakRoles";
import keycloak from "../keycloak";
import { ReactComponent as CharlieLogoCoupon } from "../layout/charlie-logo.svg";
import { dummyMerchant } from "./dummyMerchant";
import { dummyVM } from "./dummyVM";
import ImageHelper from "../components/ImageHelper";
import { couponStyles, useStyles } from "../layout/themes";
import { useTheme } from "theming";

export const EditCoupon = () => {
  let green = "#9ACD32";
  let { couponId } = useParams();
  let navigate = useNavigate();
  const path = useLocation().pathname;
  const { Step } = Steps;
  const { Title } = Typography;
  const { RangePicker } = DatePicker;
  const { Option } = Select;
  const { confirm } = Modal;
  const intl = useIntl();
  const form = new FormData();
  let merchantId: string | undefined;
  let authToken: any = keycloak.idTokenParsed ? keycloak.idTokenParsed : false;
  const styles = couponStyles({ theme: useTheme() });

  //Data Sauce
  const { data: modulesOptions, isLoading: modulesAreLoading } = useQuery(["modules"], () =>
    api.vendingMachineTypes.findAll()
  );
  const { data: merchantOptions, isLoading: merchantIsLoading } = KeycloakRoles()?.includes("merchant_admin")
    ? useQuery(["merchants"], () => api.merchants.findById(authToken.mid).then((merchant) => [merchant]))
    : useQuery(["merchants"], () => api.merchants.findAll());

  const { data: couponEdit, isLoading: couponLoading } =
    path !== "/coupons/new"
      ? useQuery(["coupon"], () => api.coupons.findById(couponId))
      : { data: null, isLoading: false };

  const { data: couponLogo, isLoading: couponLogoIsLoading } =
    path !== "/coupons/new"
      ? useQuery(["loadlogo"], () => api.coupons.fetchLogo(couponId), { cacheTime: 0 })
      : { data: null, isLoading: false };

  async function extractedLoadingSerialNumbers(mid: string) {
    if (mid != null) {
      merchantId = mid;
      let serialNumberList = await api.vendingMachines.findAllSerialNumberMerchant(mid);
      let vmSerialKeys = (serialNumberList || []).map((pos) => (
        <Option value={pos.serialNumber}>{pos.serialNumber}</Option>
      ));
      handleChangeField("serialKeyList", vmSerialKeys);
    }
  }
  async function extractedLoadingVMTypes(mid: string) {
    if (mid != null) {
      merchantId = mid;
      let vmTypesList = await api.vendingMachines.findVMTypesByMerchant(mid);
      let vmTypes = (vmTypesList || []).map((pos) => <Option value={pos.type}>{pos.type}</Option>);
      handleChangeField("vmTypesList", vmTypes);
    }
  }

  //Listvalues
  useEffect(() => {
    let merchantArray = (modulesOptions || []).map((module) => (
      <Option value={module.type}>{module.description}</Option>
    ));
    handleChangeField("modulesList", merchantArray);
  }, [modulesOptions, merchantOptions]);

  //Load merchantList once: avoid overriding merchant from EditCoupon
  useEffect(() => {
    if (Array.isArray(merchantOptions)) {
      let merchantArray = ((merchantOptions as Merchant[]) || []).map((merchant) => (
        <Option value={merchant.mid}>{merchant.name}</Option>
      ));
      handleChangeField("merchantList", merchantArray);
      let merchants = merchantOptions as Merchant[];
      handleChangeField("merchant", merchants[0].mid);
    } else if (merchantOptions !== undefined) {
      let merchant = merchantOptions as Merchant;
      handleChangeField("merchantList", [<Option value={merchant.mid}>{merchant.name}</Option>]);
      handleChangeField("merchant", merchant.mid);
    }
  }, []);

  // TODO: HANS-1607 - Auslagern in Konstante, wird in diversen Komponenten immer wieder verwendet.
  const networkList = [
    <Option value="MasterCard">MasterCard</Option>,
    <Option value="VISA">VISA</Option>,
    <Option value="AMEX">AMEX</Option>,
  ];

  //State
  const initialState = {
    //Step1
    cid: 0,
    currentStep: 0,
    valueToggle: false,
    termsOfUse: "",
    name: "",
    flat: 0,
    percent: 0,
    amount: "percent",
    //Step2
    mndValue: 0,
    network: [],
    preMndValue: 0,
    preNetwork: [],
    active: false,
    imageUrl: "",
    loading: false,
    logoPreview: form,
    //Checked
    mndValueCheck: false,
    dateCheck: false,
    modulesCheck: false,
    typesCheck: false,
    paymentCheck: false,
    preMndValueCheck: false,
    preDateCheck: false,
    preModulesCheck: false,
    preTypesCheck: false,
    prePaymentCheck: false,
    multiUseToggle: false,
  };
  const reducer = (state: any, action: any) => {
    switch (action.type) {
      case "field":
        return { ...state, [action.fieldname]: action.payload };
      default:
        throw new Error();
    }
  };
  const [state, dispatch] = useReducer(reducer, initialState);
  const [isAdmin] = useState(KeycloakRoles()?.includes("admin"));
  const [toggleColor, setToggleColor] = useState("");
  //TODO: HANS-1608 - auslagern, stepsEdit in intl. auslagern, steps als eigene Komponenten
  //Steps
  const stepsNew = [
    { index: 1, title: intl.formatMessage({ id: "coupons.form.title.step1" }) },
    { index: 2, title: intl.formatMessage({ id: "coupons.form.title.step2" }) },
    { index: 3, title: intl.formatMessage({ id: "coupons.form.title.step3" }) },
  ];
  const stepsEdit = [
    { index: 1, title: "Coupon bearbeiten" },
    { index: 2, title: "Einschränkungen bearbeiten" },
  ];
  const next = () => {
    handleChangeField("currentStep", currentStep + 1);
  };
  const prev = () => {
    handleChangeField("currentStep", currentStep - 1);
  };

  const handleChangeField = (fieldname: string, fieldvalue: any) => {
    dispatch({ type: "field", payload: fieldvalue, fieldname });
  };
  //Effects
  useEffect(() => {
    if (!couponLogoIsLoading && couponLogo) {
      handleChangeField("imageUrl", URL.createObjectURL(couponLogo));
    }
  }, [couponLogo, couponLogoIsLoading]);

  useEffect(() => {
    if (couponEdit === null && !merchantIsLoading && isAdmin) {
      let merchantArray = (merchantOptions as Merchant[]) || [];
      let selectedMerchant = merchantArray.filter((m) => m.mid == merchant);
      handleChangeField("currency", selectedMerchant[0]?.currency);
      extractedLoadingVMTypes(merchant!).then((r) => r);
      extractedLoadingSerialNumbers(merchant!).then((r) => r);
    } else if (couponEdit === null && !merchantIsLoading) {
      let tmp = merchantOptions as Merchant[];
      handleChangeField("currency", tmp[0].currency);
      extractedLoadingVMTypes(tmp[0].mid!).then((r) => r);
      extractedLoadingSerialNumbers(tmp[0].mid!).then((r) => r);
    }
  }, [state.merchant]);

  useEffect(() => {
    merchantId = couponEdit?.merchant.mid;
    extractedLoadingVMTypes(merchantId!).then((x) => x);
    extractedLoadingSerialNumbers(merchantId!).then((r) => r);

    if (couponEdit !== null) {
      //Values
      handleChangeField("merchant", couponEdit?.merchant.mid);
      handleChangeField("currency", couponEdit?.merchant.currency);
      handleChangeField("amount", String(couponEdit?.amountType));
      handleChangeField("percent", couponEdit?.amountType === "percent" ? Number(couponEdit.amount) / 100 : 0);
      handleChangeField("multiUseToggle", couponEdit?.multiUse);
      handleChangeField("flat", couponEdit?.amountType === "flat" ? Number(couponEdit.amount) / 100 : 0);
      handleChangeField("name", String(couponEdit?.name));
      handleChangeField("mndValue", Number(couponEdit?.minUseAmount) / 100);
      handleChangeField("start", couponEdit?.useStart ? moment.utc(String(couponEdit?.useStart)) : null);
      handleChangeField("end", couponEdit?.useEnd ? moment.utc(String(couponEdit?.useEnd)) : null);
      handleChangeField(
        "modules",
        couponEdit?.vendingMachines ? couponEdit?.vendingMachines.map((vm) => vm.serialNumber) : []
      );
      handleChangeField("network", couponEdit?.networks ? couponEdit.networks.map((net) => net.brand) : []);
      handleChangeField(
        "types",
        couponEdit?.vendingMachineTypes ? couponEdit.vendingMachineTypes.map((vmT) => vmT.type) : []
      );
      handleChangeField(
        "preModules",
        couponEdit?.prevendingMachines ? couponEdit?.prevendingMachines.map((vm) => vm.serialNumber) : []
      );
      handleChangeField("preNetwork", couponEdit?.prenetworks ? couponEdit.prenetworks.map((net) => net.brand) : []);
      handleChangeField(
        "preTypes",
        couponEdit?.prevendingMachineTypes ? couponEdit.prevendingMachineTypes.map((vmT) => vmT.type) : []
      );
      handleChangeField("preMndValue", Number(couponEdit?.minReceiveAmount) / 100);
      handleChangeField("preStart", couponEdit?.receiveStart ? moment.utc(String(couponEdit?.receiveStart)) : null);
      handleChangeField("preEnd", couponEdit?.receiveEnd ? moment.utc(String(couponEdit?.receiveEnd)) : null);
      handleChangeField("valueToggle", String(couponEdit?.amountType) === "percent" ? false : true);
      handleChangeField("active", couponEdit?.unlocked ? couponEdit.unlocked : false);
      handleChangeField("termsOfUse", couponEdit?.termsOfUse !== undefined ? String(couponEdit?.termsOfUse) : "");
      //Checks
      handleChangeField("mndValueCheck", couponEdit?.minUseAmount ? true : false);
      handleChangeField("dateCheck", couponEdit?.useStart ? true : false);
      handleChangeField(
        "modulesCheck",
        couponEdit?.vendingMachines && couponEdit.vendingMachines.length > 0 ? true : false
      );
      handleChangeField(
        "typesCheck",
        couponEdit?.vendingMachineTypes && couponEdit?.vendingMachineTypes.length > 0 ? true : false
      );
      handleChangeField("paymentCheck", couponEdit?.networks && couponEdit.networks.length > 0 ? true : false);
      handleChangeField(
        "preModulesCheck",
        couponEdit?.prevendingMachines && couponEdit.prevendingMachines.length > 0 ? true : false
      );
      handleChangeField(
        "preTypesCheck",
        couponEdit?.prevendingMachineTypes && couponEdit?.prevendingMachineTypes.length > 0 ? true : false
      );
      handleChangeField("prePaymentCheck", couponEdit?.prenetworks && couponEdit.prenetworks.length > 0 ? true : false);
      handleChangeField("preMndValueCheck", couponEdit?.minReceiveAmount ? true : false);
      handleChangeField("preDateCheck", couponEdit?.receiveStart ? true : false);
    }
  }, [couponEdit]);

  //Functions
  const vmDummy = (serial: string) => {
    let newVM = JSON.parse(JSON.stringify(dummyVM));
    newVM.serialNumber = serial;
    return newVM;
  };
  const merchantDummy = (mid: string) => {
    let newMerchant = dummyMerchant;
    newMerchant.mid = mid;
    return newMerchant;
  };
  const vmTypeDummy = (type: string) => {
    let newType = { type: type, description: "" };
    return newType;
  };

  const listPrint = (array: Array<any>) => {
    let listString = "";
    array.forEach((element) => {
      listString = listString + element;
    });
    return listString;
  };

  const save = () => {
    let vmArray = [] as VendingMachine[];
    modules?.forEach((vm: any) => {
      vmArray.push(vmDummy(vm));
    });
    let typeArray = [] as VendingMachineType[];
    types?.forEach((type: any) => {
      typeArray.push(vmTypeDummy(type));
    });

    // TODO: HANS-1607
    const networkArray = [
      {
        brand: network?.includes("AMEX") && "AMEX",
      },
      {
        brand: network?.includes("MasterCard") && "MasterCard",
      },
      {
        brand: network?.includes("VISA") && "VISA",
      },
    ].filter((network) => network.brand !== false);

    let prevmArray = [] as VendingMachine[];
    preModules?.forEach((vm: string) => {
      prevmArray.push(vmDummy(vm));
    });
    let pretypeArray = [] as VendingMachineType[];
    preTypes?.forEach((type: string) => {
      pretypeArray.push(vmTypeDummy(type));
    });

    // TODO: HANS-1607
    const prenetworkArray = [
      {
        brand: preNetwork?.includes("AMEX") && "AMEX",
      },
      {
        brand: preNetwork?.includes("MasterCard") && "MasterCard",
      },
      {
        brand: preNetwork?.includes("VISA") && "VISA",
      },
    ].filter((network) => network.brand !== false);

    const coupon = {
      cid: 0,
      receiveStart: preDateCheck ? preStart : "",
      receiveEnd: preDateCheck ? preEnd : "",
      useStart: dateCheck ? start : "",
      useEnd: dateCheck ? end : "",
      amount: amount === "percent" ? String(percent * 100) : String(flat * 100),
      amountType: amount,
      minReceiveAmount: preMndValueCheck ? String(preMndValue * 100) : "0",
      minUseAmount: mndValueCheck ? String(mndValue * 100) : "0",
      name: name,
      merchant: merchantDummy(merchant ? merchant : ""),
      vendingMachines: modulesCheck ? vmArray : [],
      vendingMachineTypes: typesCheck ? typeArray : [],
      networks: paymentCheck ? (networkArray as Network[]) : [],
      prevendingMachines: preModulesCheck ? prevmArray : [],
      prevendingMachineTypes: preTypesCheck ? pretypeArray : [],
      prenetworks: prePaymentCheck ? (prenetworkArray as Network[]) : [],
      unlocked: active,
      termsOfUse: termsOfUse,
      multiUse: multiUseToggle,

    };

    if (path === "/coupons/new") {
      api.coupons
        .create(coupon)
        .then((res) => {
          logoPreview !== form && api.coupons.uploadLogo(String(res.cid), logoPreview);
        })
        .then(() => navigate(-1));
    } else {
      api.coupons.update(String(couponEdit?.cid), coupon).then(() => navigate(-1));
    }
  };

  function beforeUpload(file: any) {
    const isImageTypeValid = ImageHelper.isImageTypeValid(file);
    if (!ImageHelper.isImageTypeValid(file)) {
      message.error(intl.formatMessage({ id: "merchants.logo.dataformat" }));
    }
    const isFileSizeValid = ImageHelper.isFileSizeValid(file);
    if (!isFileSizeValid) {
      message.error(intl.formatMessage({ id: "merchants.logo.datasize" }));
    }
    return isImageTypeValid && isFileSizeValid;
  }

  const handleColorChange=() => {
    const newColor = (multiUseToggle && toggleColor === green) ? "" : green;
    setToggleColor(newColor);
  }

  const handleChange = (info: any) => {
    if (info.file.status === "uploading") {
      dispatch({ type: "field", payload: true, fieldname: "loading" });
      return;
    }
  };

  const uploadButton = (
    <div>
      {state.loading ? <LoadingOutlined /> : <PlusOutlined style={{ fontSize: "32px" }} />}
      <div className="ant-upload-text">Upload</div>
    </div>
  );

  const onFileUpload = (event: File) => {
    var formData = new FormData();
    formData.append("file", event, event.name);
    handleChangeField("logoPreview", formData);

    let objectURL = URL.createObjectURL(event);
    dispatch({ type: "field", payload: objectURL, fieldname: "imageUrl" });

    if (couponId !== undefined) {
      api.coupons.uploadLogo(couponId, formData).then((value) => {
        let objectUrl = URL.createObjectURL(value);
        let reloadImage = queryCache.fetchQuery("loadlogo", () => api.coupons.fetchLogo(couponId));
      });
    }
    return imageUrl;
  };

  const downloadLogo = async () => {
    const link = document.createElement("a");
    document.body.appendChild(link);
    link.style.display = "none";

    const url = URL.createObjectURL(couponLogo);

    link.href = url;
    link.download = couponId;
    link.click();
  };

  function deleteLogo() {
    if (couponLogo !== null) {
      confirm({
        title: intl.formatMessage({ id: "delete.modal.title.logo" }),
        icon: <ExclamationCircleOutlined />,
        okText: intl.formatMessage({ id: "common.yes" }),
        okType: "danger",
        cancelText: intl.formatMessage({ id: "common.no" }),
        async onOk() {
          try {
            await api.coupons.deleteLogo(couponId);
            let res = queryCache.invalidateQueries("loadLogo");
            handleChangeField("imageUrl", "");
            handleChangeField("loading", false);
            message.success("Deletion successful");
          } catch (error) {
            message.error("Deletion failed!");
          }
        },
      });
    } else {
      handleChangeField("logoPreview", form);
      handleChangeField("imageUrl", "");
      handleChangeField("loading", false);
    }
  }
  const {
    //Options
    serialKeyList,
    vmTypesList,
    //Step1
    cid,
    currentStep,
    valueToggle,
    multiUseToggle,
    termsOfUse,
    name,
    flat,
    percent,
    amount,
    merchant,
    merchantList,
    currency,
    //Step2
    mndValue,
    network,
    preMndValue,
    preNetwork,
    active,
    imageUrl,
    loading,
    logoPreview,
    modules,
    types,
    start,
    end,
    preStart,
    preEnd,
    preModules,
    preTypes,
    //Checked
    mndValueCheck,
    dateCheck,
    modulesCheck,
    typesCheck,
    paymentCheck,
    preMndValueCheck,
    preDateCheck,
    preModulesCheck,
    preTypesCheck,
    prePaymentCheck,
  } = state;

  //Render
  return (
    <>
      <Row justify="center" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className={styles.container}>
        <Col lg={20}>
          <Steps current={currentStep}>
            {path === "/coupons/new" && stepsNew.map((item) => <Step key={item.index} title={item.title} />)}
            {path !== "/coupons/new" && stepsEdit.map((item) => <Step key={item.index} title={item.title} />)}
          </Steps>
        </Col>
        <Row justify="start">
          <Col lg={20} className={styles.container}>
            <p className={styles.notification}>
              <FormattedMessage id="coupon.notification" />
            </p>
          </Col>
        </Row>
        <Col lg={20} className={styles.container}>
          {currentStep === 0 && (
            <>
              <Row justify={"center"}>
                <Title level={4}>
                  <FormattedMessage id="coupons.form.title.basic" />
                </Title>
              </Row>
              <Row justify="center">
                <Card size="default" className={styles.card}>
                  <p>
                    <Boop
                      scale={1.1}
                      style={{ width: 100 }}
                      children={<CharlieLogoCoupon style={{ width: "100%", height: "100%" }} />}
                    />
                  </p>
                  {path !== "/coupons/new" && (
                    <p style={{ marginLeft: "35%", marginTop: -40 }}>
                      <ImageUpload
                        imageUrl={imageUrl}
                        loading={loading}
                        deleteLogo={deleteLogo}
                        className={"coupon-logo"}
                        handleChange={handleChange}
                        onFileUpload={onFileUpload}
                        downloadLogo={downloadLogo}
                      />
                    </p>
                  )}
                  <p style={{ textAlign: "center" }} className={styles.cardTitle}>
                    {
                      <Select
                        value={merchant}
                        onChange={(value) => {
                          handleChangeField("merchant", value),
                            extractedLoadingVMTypes(value),
                            extractedLoadingSerialNumbers(value);
                        }}
                        className={styles.cardSelect}
                      >
                        {merchantList}
                      </Select>
                    }
                  </p>
                  <p style={{ textAlign: "center" }}>
                    <Input
                      value={name}
                      onChange={(event) => handleChangeField("name", event.target.value)}
                      placeholder={intl.formatMessage({ id: "coupon.form.title.name" })}
                      className={styles.input}
                    />
                  </p>
                  <p>
                    <Switch
                      checkedChildren={intl.formatMessage({ id: "coupon.form.title.flat" })}
                      unCheckedChildren={intl.formatMessage({ id: "coupon.form.title.percent" })}
                      checked={valueToggle}
                      onChange={(event) => handleChangeField("valueToggle", !valueToggle)}
                    />
                  </p>
                  <p>
                    {!valueToggle && (
                      <InputNumber
                        value={percent}
                        onChange={(value) => {
                          handleChangeField("percent", Number(value));
                          handleChangeField("amount", "percent");
                        }}
                        min={0}
                        max={100}
                        formatter={(value) => `${value}%`}
                      />
                    )}
                    {valueToggle ? (
                      <p className={styles.cardFont}>
                        <InputNumber
                          value={flat}
                          onChange={(value) => {
                            handleChangeField("flat", Number(value));
                            handleChangeField("amount", "flat");
                          }}
                          min={0}
                          step={0.01}
                          precision={2}
                          decimalSeparator={","}
                          formatter={(value) => `${value}`}
                        />
                        {" " + currency}
                      </p>
                    ) : null}
                  </p>
                  <p>
                    <Switch
                      checkedChildren={intl.formatMessage({ id: "coupon.form.title.multi" })}
                      unCheckedChildren={intl.formatMessage({ id: "coupon.form.title.multiUse" })}
                      checked={multiUseToggle}
                      style={{ backgroundColor: toggleColor }}
                      onClick={handleColorChange}
                      onChange={(event) => handleChangeField("multiUseToggle", !multiUseToggle)}
                    />
                  </p>
                  {path !== "/coupons/new" && (
                    <>
                      <p className={styles.cardFont}>{intl.formatMessage({ id: "coupon.card.text.termsofuse" })}</p>
                      <p>
                        <Input.TextArea
                          value={termsOfUse}
                          onChange={(event) => handleChangeField("termsOfUse", event.target.value)}
                          style={{ height: 100, overflow: "visible" }}
                          className={styles.input}
                        />
                      </p>
                      <p className={styles.cardFont}>
                        <FormattedMessage id="vendingmachine.clearance.title" />
                        <Switch
                          checkedChildren={intl.formatMessage({ id: "vendingmachine.clearance.value.positive" })}
                          unCheckedChildren={intl.formatMessage({ id: "vendingmachine.clearance.value.negative" })}
                          checked={active}
                          onChange={(event) => handleChangeField("active", !active)}
                        />
                      </p>
                    </>
                  )}
                </Card>
              </Row>
            </>
          )}
          {currentStep === 1 && (
            <>
              <Row justify="center">
                <h1>{merchant}</h1>
              </Row>
              <Row justify="center">
                <h1>
                  {intl.formatMessage({ id: "coupon.form.title.name" })}: {name},{" "}
                  {intl.formatMessage({ id: "coupon.card.title" })}:{" "}
                  {amount === "percent" ? (
                    percent + "%"
                  ) : (
                    <FormattedNumber value={flat} style="currency" currency={currency} />
                  )}
                </h1>
              </Row>
              <Row justify={"space-around"}>
                <Col lg={10} style={{ marginLeft: "16%" }}>
                  <Row className={styles.container}>
                    <Title level={4}>
                      <FormattedMessage id="coupons.form.title.get" />
                    </Title>
                  </Row>
                  <Row justify="start" className={styles.container}>
                    <Condition
                      input={
                        <RangePicker
                          value={[preStart, preEnd]}
                          onChange={(value) => {
                            if (value && value[0] && value[1]) {
                              handleChangeField("preStart", value[0]);
                              handleChangeField("preEnd", value[1]);
                            }
                          }}
                        />
                      }
                      text={intl.formatMessage({ id: "conditions.timerange" })}
                      background="#DCF56E"
                      color="#000"
                      checked={preDateCheck}
                      onToggle={() => handleChangeField("preDateCheck", !preDateCheck)}
                    />
                  </Row>
                  <Row justify="start" className={styles.container}>
                    <Condition
                      input={
                        <p className={styles.inputField}>
                          <InputNumber
                            min={0}
                            step={0.01}
                            decimalSeparator={","}
                            formatter={(value) => `${value}`}
                            value={preMndValue}
                            precision={2}
                            onChange={(value) => handleChangeField("preMndValue", Number(value))}
                          />
                          <>{" " + currency}</>
                        </p>
                      }
                      text={intl.formatMessage({ id: "conditions.min.amount" })}
                      background="#7D69A9"
                      color="#FFF"
                      checked={preMndValueCheck}
                      onToggle={() => handleChangeField("preMndValueCheck", !preMndValueCheck)}
                    />
                  </Row>
                  <Row justify="start" className={styles.container}>
                    <Condition
                      input={
                        <Select
                          mode="multiple"
                          className={styles.select}
                          value={preModules}
                          onChange={(value) => {
                            handleChangeField("preModules", value);
                          }}
                        >
                          {serialKeyList}
                        </Select>
                      }
                      text={intl.formatMessage({ id: "conditions.modules" })}
                      background="#DCF56E"
                      color="#000"
                      checked={preModulesCheck}
                      onToggle={() => handleChangeField("preModulesCheck", !preModulesCheck)}
                    />
                  </Row>
                  <Row justify="start" className={styles.container}>
                    <Condition
                      input={
                        <Select
                          mode="multiple"
                          className={styles.select}
                          value={preTypes}
                          onChange={(value) => {
                            handleChangeField("preTypes", value);
                          }}
                        >
                          {vmTypesList}
                        </Select>
                      }
                      text={intl.formatMessage({ id: "conditions.modules.type" })}
                      background="#7D69A9"
                      color="#FFF"
                      checked={preTypesCheck}
                      onToggle={() => handleChangeField("preTypesCheck", !preTypesCheck)}
                    />
                  </Row>
                  <Row justify="start" className={styles.container}>
                    <Condition
                      input={
                        <Select
                          mode="multiple"
                          className={styles.select}
                          value={preNetwork}
                          onChange={(value) => handleChangeField("preNetwork", value)}
                        >
                          {networkList}
                        </Select>
                      }
                      text={intl.formatMessage({ id: "conditions.payment" })}
                      background="#DCF56E"
                      color="#000000"
                      checked={prePaymentCheck}
                      onToggle={() => handleChangeField("prePaymentCheck", !prePaymentCheck)}
                    />
                  </Row>
                </Col>
                <Col lg={10}>
                  <Row className={styles.container}>
                    <Title level={4}>
                      <FormattedMessage id="coupons.form.title.post" />
                    </Title>
                  </Row>
                  <Row justify="start" className={styles.container}>
                    <Condition
                      input={
                        <RangePicker
                          format="DD.MM.YYYY"
                          value={[start, end]}
                          onChange={(value) => {
                            if (value && value[0] && value[1]) {
                              handleChangeField("start", value[0]);
                              handleChangeField("end", value[1]);
                            }
                          }}
                        />
                      }
                      text={intl.formatMessage({ id: "conditions.timerange" })}
                      background="#DCF56E"
                      color="#000"
                      checked={dateCheck}
                      onToggle={() => handleChangeField("dateCheck", !dateCheck)}
                    />
                  </Row>
                  <Row justify="start" className={styles.container}>
                    <Condition
                      input={
                        <p className={styles.inputField}>
                          <InputNumber
                            min={0}
                            step={0.01}
                            decimalSeparator={","}
                            formatter={(value) => `${value}`}
                            value={mndValue}
                            precision={2}
                            onChange={(value) => handleChangeField("mndValue", Number(value))}
                          />
                          <>{" " + currency}</>
                        </p>
                      }
                      text={intl.formatMessage({ id: "conditions.min.amount" })}
                      background="#7D69A9"
                      color="#FFF"
                      checked={mndValueCheck}
                      onToggle={() => handleChangeField("mndValueCheck", !mndValueCheck)}
                    />
                  </Row>
                  <Row justify="start" className={styles.container}>
                    <Condition
                      input={
                        <Select
                          mode="multiple"
                          className={styles.select}
                          value={modules}
                          onChange={(value) => {
                            handleChangeField("modules", value);
                          }}
                        >
                          {serialKeyList}
                        </Select>
                      }
                      text={intl.formatMessage({ id: "conditions.modules" })}
                      background="#DCF56E"
                      color="#000"
                      checked={modulesCheck}
                      onToggle={() => handleChangeField("modulesCheck", !modulesCheck)}
                    />
                  </Row>
                  <Row justify="start" className={styles.container}>
                    <Condition
                      input={
                        <Select
                          mode="multiple"
                          className={styles.select}
                          value={types}
                          onChange={(value) => {
                            handleChangeField("types", value);
                          }}
                        >
                          {vmTypesList}
                        </Select>
                      }
                      text={intl.formatMessage({ id: "conditions.modules.type" })}
                      background="#7D69A9"
                      color="#FFF"
                      checked={typesCheck}
                      onToggle={() => handleChangeField("typesCheck", !typesCheck)}
                    />
                  </Row>
                  <Row justify="start" className={styles.container}>
                    <Condition
                      input={
                        <Select
                          mode="multiple"
                          className={styles.select}
                          value={network}
                          onChange={(value) => handleChangeField("network", value)}
                        >
                          {networkList}
                        </Select>
                      }
                      text={intl.formatMessage({ id: "conditions.payment" })}
                      background="#DCF56E"
                      color="#000"
                      checked={paymentCheck}
                      onToggle={() => handleChangeField("paymentCheck", !paymentCheck)}
                    />
                  </Row>
                </Col>
              </Row>
            </>
          )}
          {currentStep === 2 && (
            <>
              <Row justify={"center"}>
                <Title level={4}>
                  <FormattedMessage id="coupon.title.publish" />
                </Title>
              </Row>
              <Row justify="center" wrap={false}>
                <Card size="default" className={styles.card2}>
                  {" "}
                  <p>
                    <Boop
                      scale={1.1}
                      style={{ width: 100 }}
                      children={<CharlieLogoCoupon style={{ width: "100%", height: "100%" }} />}
                    />
                  </p>
                  <p style={{ textAlign: "center", marginTop: "-10%" }}>
                    <ImageUpload
                      imageUrl={imageUrl}
                      loading={loading}
                      deleteLogo={deleteLogo}
                      className={"coupon-logo"}
                      handleChange={handleChange}
                      onFileUpload={onFileUpload}
                      downloadLogo={downloadLogo}
                    />
                  </p>
                  <p style={{ textAlign: "center" }} className={styles.cardTitle}>
                    {authToken.given_name}
                  </p>
                  <p style={{ textAlign: "center" }} className={styles.cardSubTitle}>
                    {name}
                  </p>
                  <p className={styles.cardFont}>
                    {intl.formatMessage({ id: "coupon.card.title" })}:{" "}
                    {amount === "percent" ? (
                      percent + "%"
                    ) : (
                      <FormattedNumber value={flat} style="currency" currency={currency} />
                    )}
                  </p>
                  <p className={styles.cardFont}>
                    {intl.formatMessage({ id: "coupon.form.title.multi" })}:{multiUseToggle ? " "+intl.formatMessage({ id: "common.yes" }):" "+intl.formatMessage({ id: "common.no" })}
                  </p>
                  <p className={styles.cardFont}>
                    {intl.formatMessage({ id: "conditions.timerange" })}:{" "}
                    {start ? start.format("DD.MM.YYYY") : intl.formatMessage({ id: "coupon.card.noperiod" })}
                    {end ? "-" + end.format("DD.MM.YYYY") : ""}
                  </p>
                  <p style={{ textAlign: "center" }} className={styles.cardFontSmall}>
                    {mndValueCheck
                      ? intl.formatMessage({ id: "coupon.card.text.mnd.true" }) + mndValue + " " + currency + "."
                      : intl.formatMessage({ id: "coupon.card.text.mnd.false" })}
                    {modulesCheck
                      ? intl.formatMessage({ id: "coupon.card.text.pos.true" })
                      : intl.formatMessage({ id: "coupon.card.text.pos.false" })}
                    {typesCheck
                      ? intl.formatMessage({ id: "coupon.card.text.types.true" }) + vmTypesList
                      : intl.formatMessage({ id: "coupon.card.text.types.false" })}
                    {paymentCheck
                      ? intl.formatMessage({ id: "coupon.card.text.payment.true" }) + network + "."
                      : intl.formatMessage({ id: "coupon.card.text.payment.false" })}
                  </p>
                  <p className={styles.cardFont}>{intl.formatMessage({ id: "coupon.card.text.termsofuse" })}</p>
                  <p>
                    <Input.TextArea
                      value={termsOfUse}
                      onChange={(event) => handleChangeField("termsOfUse", event.target.value)}
                      className={styles.input}
                      style={{ height: 130, overflow: "visible" }}
                    />
                  </p>
                  <p className={styles.cardFont}>
                    <FormattedMessage id="vendingmachine.clearance.title" />
                    <Switch
                      checkedChildren={intl.formatMessage({ id: "vendingmachine.clearance.value.positive" })}
                      unCheckedChildren={intl.formatMessage({ id: "vendingmachine.clearance.value.negative" })}
                      checked={active}
                      onChange={() => handleChangeField("active", !active)}
                    />
                    {""}
                  </p>
                </Card>
              </Row>
            </>
          )}
          <Row justify="center" className={styles.container}>
            {currentStep === 0 && (
              <>
                {path !== "/coupons/new" && (
                  <Button type="primary" onClick={() => save()} style={{ marginRight: 10 }}>
                    <FormattedMessage id="coupon.button.save" />
                  </Button>
                )}
                <Button type="primary" onClick={() => next()}>
                  <FormattedMessage id="coupon.button.advance" />
                </Button>
              </>
            )}
            {currentStep === 1 && (
              <>
                <Button type="primary" onClick={() => prev()} style={{ marginRight: 10 }}>
                  <FormattedMessage id="coupon.button.back" />
                </Button>
                {path !== "/coupons/new" && (
                  <Button type="primary" onClick={() => save()} style={{ marginRight: 10 }}>
                    <FormattedMessage id="coupon.button.save" />
                  </Button>
                )}
                {path === "/coupons/new" && (
                  <Button type="primary" onClick={() => next()}>
                    <FormattedMessage id="coupon.button.advance" />
                  </Button>
                )}
              </>
            )}
            {currentStep === 2 && (
              <>
                <Button type="primary" onClick={() => prev()} style={{ marginRight: 10 }}>
                  <FormattedMessage id="coupon.button.back" />
                </Button>
                <Button type="primary" onClick={() => save()}>
                  <FormattedMessage id="coupon.button.save" />
                </Button>
              </>
            )}
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default EditCoupon;
