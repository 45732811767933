/* eslint-disable react/style-prop-object */
import React, { useState } from "react";
import { usePaginatedQuery, useQuery } from "react-query";
import { PageHeader, Tag } from "antd";
import { TablePaginationConfig } from "antd/lib/table";
import { Table } from "ant-table-extensions";
import { FormattedDate, FormattedNumber, FormattedMessage, useIntl } from "react-intl";
import api from "../api";

const colorMap: { [char: string]: string } = {
  SNACK_DRINKS: "orange",
  TOBACCO: "grey",
  DRINKS: "grey",
  TAXI: "yellow",
  COFFEE: "brown",
  DONATION: "purple",
};

const columns = [
  {
    title: <FormattedMessage id="transaction.history.table.statisticId" />,
    render: (record: CouponTransaction) => record?.payment?.transactionId,
  },
  {
    title: <FormattedMessage id="transaction.history.table.approval" />,
    render: (record: any) => (
      <FormattedDate
        value={new Date(record.issuedAt)}
        year={"numeric"}
        month={"2-digit"}
        day={"2-digit"}
        hour={"numeric"}
        minute={"numeric"}
      />
    ),
  },
  {
    title: <FormattedMessage id="vendingmachines.title" />,
    dataIndex: "vendingMachine",
    render: (record: VendingMachine) => record?.serialNumber,
  },
  {
    title: <FormattedMessage id="transaction.history.table.posType" />,
    dataIndex: "vendingMachine",
    render: (record: VendingMachine) => (
      <Tag color={colorMap[record?.vendingMachineType.type]}>{record?.vendingMachineType.description}</Tag>
    ),
  },
  {
    title: <FormattedMessage id="vendingmachines.table.column.merchant" />,
    dataIndex: "vendingMachine",
    render: (record: VendingMachine) => record?.merchant?.name,
  },
  {
    title: <FormattedMessage id="transaction.history.table.uuid" />,
    render: (record: CouponTransaction) => record?.uuid,
  },
  {
    title: <FormattedMessage id="transaction.history.table.revenue" />,
    render: (record: any) => (
      <FormattedNumber value={record.amount / 100} style="currency" currency={record.currency} />
    ),
    align: "right" as "right",
  },
  {
    title: <FormattedMessage id="coupons.table.column.value" />,
    render: (record: CouponTransaction) => (
      <FormattedNumber value={record.couponValue / 100} style="currency" currency={record.currency} />
    ),
  },
];

const CouponHistory = () => {
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    pageSize: 10,
    current: 1,
  });
  const { data } = useQuery("transactions", () => api.coupons.findCouponTransactions());
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const intl = useIntl();

  const fetchTransactions = async (key: any, page = 0, pageSize = 10) => {
    const response = api.coupons.findCouponTransactions();
    return await response;
  };

  const { isLoading, latestData } = usePaginatedQuery(
    ["transactions", pagination.current, pagination.pageSize],
    fetchTransactions
  );

  const handleTableChange = (pagination: TablePaginationConfig, filters: any, sorter: any) => {
    setPagination(pagination);
  };

  return (
    <>
      <PageHeader title={<FormattedMessage id="sidebar.transactions" />} />
      <div style={{ margin: "16px 24px 0" }}>
        <Table
          pagination={pagination}
          loading={isLoading || latestData === undefined}
          dataSource={data}
          columns={columns}
          rowKey={(record) => record.transactionId}
          onChange={handleTableChange}
        />
      </div>
    </>
  );
};

export default CouponHistory;
