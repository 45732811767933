import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Table } from "ant-table-extensions";
import { Badge, Button, PageHeader, Tooltip } from "antd";
import React, { useEffect } from "react";
import { FormattedMessage, useIntl, FormattedNumber } from "react-intl";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import api from "../api";

type CouponStatus = "active" | "pending" | "expired";
const getColorForTag = (tag: CouponStatus): string => {
  const map: { [index: string]: string } = {
    active: "green",
    pending: "orange",
    expired: "grey",
  };

  return map[tag];
};

const Coupons = () => {
  const intl = useIntl();
  var currentPage: number = +(sessionStorage.getItem("pageIndexCoupon") || 1);
  var currentPageSize: number = +(sessionStorage.getItem("pageSizeCoupon") || 10);

  const { isLoading, data } = useQuery("coupons", () => api.coupons.findAll(), {
    cacheTime: 0,
    enabled: true,
    refetchOnMount: "always",
  });
  const searchPlaceholder = useIntl().formatMessage({ id: "common.search" });
  const exportBtnText = useIntl().formatMessage({ id: "common.export" });

  const columns = [
    {
      title: <FormattedMessage id="coupon.table.title" />,
      dataIndex: "name",
      sorter: (a: Coupon, b: Coupon) => a.name.localeCompare(b.name),
      render: (record: String, coupons: Coupon) => (
        <Tooltip
          title={
            <span>
              <FormattedMessage id="vendingmachine.clearance.title" />
              {coupons.unlocked ? (
                <FormattedMessage id="vendingmachine.clearance.value.positive" />
              ) : (
                <FormattedMessage id="vendingmachine.clearance.value.negative" />
              )}
            </span>
          }
        >
          <Badge color={coupons.unlocked ? "#61f285" : "#f26161"} text={record} />
        </Tooltip>
      ),
    },
    {
      title: <FormattedMessage id="coupon.table.amount" />,
      dataIndex: "amount",
      render: (record: String, coupon: Coupon) => (
        <>
          {coupon.amountType !== "percent" ? (
            <FormattedNumber value={Number(record) / 100} style="currency" currency={coupon.merchant.currency} />
          ) : (
            <span>{Number(record) / 100 + " %"}</span>
          )}
        </>
      ),
    },
    {
      title: <FormattedMessage id="coupon.table.merchant" />,
      dataIndex: "merchant",
      render: (record: Merchant) => record?.name,
    },
    {
      title: <FormattedMessage id="coupon.table.couponPeriod" />,
      dataIndex: "useStart",
      render: (record: String, coupon: Coupon) => (
        <>
          <span>
            {coupon.useStart ? (
              intl.formatDate(coupon.useStart, { year: "numeric", month: "numeric", day: "numeric" }) +
              "-" +
              intl.formatDate(coupon.useEnd, { year: "numeric", month: "numeric", day: "numeric" })
            ) : (
              <FormattedMessage id="coupon.card.noperiod" />
            )}
          </span>
        </>
      ),
    },
    {
      title: <FormattedMessage id="vendingmachines.table.column.actions" />,
      render: (text: string, record: Coupon, index: number) => (
        <>
          <Tooltip title="Edit">
            <Link to={"" + record.cid}>
              <Button icon={<EditOutlined />} type="text" />
            </Link>
          </Tooltip>
        </>
      ),
    },
  ];

  return (
    <>
      <PageHeader
        title="Coupons"
        extra={
          <Link to="new">
            <Button type="primary" icon={<PlusOutlined />}>
              <FormattedMessage id="coupons.new-coupon" />
            </Button>
          </Link>
        }
      />
      <div style={{ margin: "16px 24px 0" }}>
        <Table
          rowKey={(record: Merchant) => record.mid}
          /*rowSelection={{
            type: "checkbox",
          }}*/
          columns={columns}
          dataSource={data}
          pagination={{
            defaultCurrent: Number(sessionStorage.getItem("pageIndexCoupon") || 1),
            defaultPageSize: Number(sessionStorage.getItem("pageSizeCoupon") || 10),
            onChange: (current, pageSize) => {
              sessionStorage.setItem("pageIndexCoupon", current.toString());
              sessionStorage.setItem("pageSizeCoupon", String(pageSize ? pageSize : 10));
            },
            showSizeChanger: true,
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} ${intl.formatMessage({
                id: "antd.table.pagination.of",
              })} ${total} ${intl.formatMessage({
                id: "antd.table.pagination.items",
              })}`,
          }}
          loading={isLoading}
        />
      </div>
    </>
  );
};

export default Coupons;
