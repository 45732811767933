import React, { useEffect, useState } from "react";
import { useQuery, queryCache } from "react-query";
import { Link } from "react-router-dom";
import { Button, PageHeader, Space, Tooltip } from "antd";
import { Table } from "ant-table-extensions";
import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import keycloak from "../keycloak";
import api from "../api";
import { FormattedMessage, useIntl } from "react-intl";
import KeycloakRoles from "../components/KeycloakRoles";

const Coworkers = () => {
  const intl = useIntl();
  const placeholder = intl.formatMessage({ id: "common.search" });
  let authToken: any = keycloak.idTokenParsed ? keycloak.idTokenParsed : false;

  const [data, setData] = useState(Array<any>());

  useEffect(() => {
    const keycloakUsers = queryCache.fetchQuery("keycloak", () => api.keycloak.getUsers(authToken.mid));
    keycloakUsers
      .then((res) => {
        let userArray = Array<any>();
        let arr = Array.from(res);
        arr.forEach((user: any) => {
          userArray.push({
            username: user.username.toString(),
            merchant: user.attributes?.mid ? user.attributes.mid[0] : "",
            pos: user.attributes?.pos ? user.attributes?.pos[0] : "",
            id: user.id,
          });
        });
        setData(userArray);
      })
      .catch((err) => console.log("error fetching table data", err));
  }, []);

  const columns = [
    {
      title: intl.formatMessage({ id: "coworkers.edit.name" }),
      dataIndex: "username",
      sorter: (a: any, b: any) => a?.username.localeCompare(b?.username),
    },
    {
      title: intl.formatMessage({ id: "merchants.table.column.merchant-name" }),
      dataIndex: "merchant",
      sorter: (a: any, b: any) => a?.merchant.localeCompare(b?.merchant),
    },
    {
      title: intl.formatMessage({ id: "vendingmachines.title" }),
      dataIndex: "pos",
      sorter: (a: any, b: any) => a?.pos.localeCompare(b?.pos),
    },
    {
      title: intl.formatMessage({ id: "merchants.table.column.actions" }),
      dataIndex: "id",
      render: (text: string) => (
        <>
          <Tooltip
            title={intl.formatMessage({
              id: "merchants.table.column.actions.edit",
            })}
          >
            <Link to={text}>
              <Button icon={<EditOutlined />} type="text" />
            </Link>
          </Tooltip>
        </>
      ),
    },
  ];

  return (
    <>
      <PageHeader
        title={intl.formatMessage({ id: "coworkers.title" })}
        extra={[
          <Link to="new" key="1">
            <Button type="primary" icon={<PlusOutlined />}>
              <FormattedMessage id="coworkers.new-coworker" />
            </Button>
          </Link>,
        ]}
      />
      <div style={{ margin: "16px 24px 0" }}>
        <Table
          searchable
          searchableProps={{
            inputProps: {
              placeholder: placeholder,
            },
          }}
          dataSource={data}
          columns={columns}
          pagination={{
            showSizeChanger: true,
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} ${intl.formatMessage({
                id: "antd.table.pagination.of",
              })} ${total} ${intl.formatMessage({
                id: "antd.table.pagination.items",
              })}`,
          }}
        />
      </div>
    </>
  );
};

export default Coworkers;
