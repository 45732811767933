/* eslint-disable react/style-prop-object */
import React from "react";
import { Table } from "antd";
import { useQuery } from "react-query";
import api from "../api";
import { FormattedMessage, FormattedNumber } from "react-intl";

const columns = [
  {
    title: <FormattedMessage id="transaction.statistics.table.paymentMethod" />,
    dataIndex: "key",
    render: (value: string) => <FormattedMessage id={"payment.method." + value} />,
    sorter: (a: any, b: any) => a?.key.localeCompare(b?.key),
  },
  {
    title: <FormattedMessage id="transaction.statistics.table.sales" />,
    dataIndex: "paymentCount",
    align: "right" as "right",
    sorter: (a: any, b: any) => a.paymentCount - b.paymentCount,
  },
  {
    title: <FormattedMessage id="transaction.history.table.revenue" />,
    align: "right" as "right",
    render: (value: any) => value.currency !== value.multiCurrency ? <FormattedMessage id="transaction.history.table.multipleCurrencies" /> : <FormattedNumber style="currency" currency={value.currency} value={value.sumAmount / 100} />,
    sorter: (a: any, b: any) => a.sumAmount - b.sumAmount,
  },
];

type StatisticsPerPaymentMethodTableProps = {
  merchant: string;
  from?: string;
  until?: string;
  currency?: string;
};

const StatisticsPerPaymentMethodTable: React.FC<StatisticsPerPaymentMethodTableProps> = ({ merchant, from, until, currency }) => {
  const { data } = useQuery(
    ["statistic-total", merchant, from, until, currency],
    () => api.payments.findStatisticTotalById(merchant, from, until, currency),
    {
      enabled: [merchant, from, until, currency],
    }
  );

  const { data: statisticsAllPaymentTypes } = useQuery(
    ["statistic-all-paymenttypes", from, until, currency],
    () => api.payments.findStatisticAllPaymentsTable(from, until, currency),
    {
      enabled: [from, until, currency],
    }
  );

  const getPaymentStatistics = () => {
    return data
      ? data?.[0]?.subPaymentStatistic
      : merchant === "" || merchant === undefined
        ? statisticsAllPaymentTypes?.[0]?.subPaymentStatistic
        : [];
  };

  return (
    <Table
      scroll={{ x: true }}
      title={() => <FormattedMessage id="statistics.table.payment.method" />}
      columns={columns}
      dataSource={getPaymentStatistics()}
      pagination={{ hideOnSinglePage: true }}
    />
  );
};

export default StatisticsPerPaymentMethodTable;
