import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Table } from "ant-table-extensions";
import { Badge, Button, Input, PageHeader, Row, Select, Tag, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { queryCache, useQuery } from "react-query";
import { Link } from "react-router-dom";
import api from "../api";
import CustomPaginationNav from "../components/CustomPaginationNav";
import KeycloakRoles from "../components/KeycloakRoles";
import keycloak from "../keycloak";

const colorMap: { [char: string]: string } = {
  SNACK_DRINKS: "orange",
  TOBACCO: "grey",
  DRINKS: "grey",
  TAXI: "yellow",
  COFFEE: "brown",
  DONATION: "purple",
};

const { Option } = Select;
type MerchaneSelectProps = {
  onChange(mid: any): void;
  value: string;
};

const MerchantSelect: React.FC<MerchaneSelectProps> = ({ onChange, value }) => {
  const { data: merchants, isLoading } = useQuery("merchants", () => api.merchants.findAll());
  const [valued, setValued] = useState(value);

  return (
    <Select
      showSearch
      style={{ width: 320 }}
      placeholder={<FormattedMessage id="transaction.statistics.selectMerchant" />}
      optionFilterProp="children"
      onChange={(mid) => {
        onChange(mid);
        console.log(mid);
        setValued(String(mid));
      }}
      loading={isLoading}
      allowClear
      value={value === "" ? undefined : value}
    >
      {merchants
        ?.sort((a, b) => a.name.localeCompare(b.name))
        .map((merchant) => (
          <Option value={merchant.mid} key={merchant.mid}>
            {merchant.name}
          </Option>
        ))}
    </Select>
  );
};

const VendingMachines = () => {
  const [data, setData] = useState<VendingMachine[]>([]);
  var num_pageSize: number = +(sessionStorage.getItem("pageIndex") || 0);
  var num_entriesSize: number = +(sessionStorage.getItem("entriesSize") || 10);
  const [pagination, setPagination] = useState({ index: num_pageSize, entries: num_entriesSize });
  const [sort, setSort] = useState(localStorage.getItem("sort") || "serialNumber");
  const [direction, setDirection] = useState(localStorage.getItem("direction") || "asc");
  const [search, setSearch] = useState(localStorage.getItem("search") || "");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [pageSize, setPageSize] = useState<number>(1);

  const [showAddPOS, setShowAddPOS] = useState(false);
  const intl = useIntl();
  let keycloakRoles = KeycloakRoles();
  const [selected, setSelected] = useState([""]);
  const [isAdmin] = useState(KeycloakRoles()?.includes("admin"));
  const [isMerchantAdmin] = useState(KeycloakRoles()?.includes("merchant_admin"));
  let authToken: any = keycloak.idTokenParsed ? keycloak.idTokenParsed : false;
  const [merchantId, setMerchantId] = useState(localStorage.getItem("merchantId") || (isAdmin ? "" : authToken.mid));

  const toggleShowAddPOS = async () => {
    if (keycloakRoles) {
      if (keycloakRoles.includes("merchant_admin")) {
        let authToken: any = keycloak.idTokenParsed ? keycloak.idTokenParsed : false;
        if (authToken) {
          const merchant = await queryCache.fetchQuery("grantedModules", () => api.merchants.findById(authToken.mid));
          let modules = merchant?.vendingMachineTypes;
          if (modules.length > 0) {
            setShowAddPOS(true);
          }
        }
      } else if (keycloakRoles.includes("admin")) {
        setShowAddPOS(true);
      }
    }
  };

  useEffect(() => {
    if (search === "") {
      api.vendingMachines.page(pagination.entries, pagination.index, merchantId, sort, direction, "").then((res) => {
        setData(res);
      });
      localStorage.setItem("merchantId", merchantId === undefined ? "" : merchantId);
      localStorage.setItem("sort", sort);
      localStorage.setItem("direction", direction);
      localStorage.setItem("search", search);
      api.vendingMachines.size(merchantId === undefined ? "" : merchantId, search).then((res) => setPageSize(res));
    } else {
      api.vendingMachines
        .page(pagination.entries, pagination.index, merchantId, sort, direction, search)
        .then((res) => {
          setData(res);
          api.vendingMachines.size(merchantId === undefined ? "" : merchantId, search).then((res) => setPageSize(res));
        });
      localStorage.setItem("merchantId", merchantId);
      localStorage.setItem("sort", sort);
      localStorage.setItem("direction", direction);
      localStorage.setItem("search", search);
    }
  }, [pagination, merchantId, sort, direction, search]);

  useEffect(() => {
    toggleShowAddPOS();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keycloakRoles]);

  useEffect(() => {
    if (isAdmin) {
    }
  }, [isAdmin]);

  useEffect(() => {
    if (isMerchantAdmin) {
    }
  }, [isMerchantAdmin]);

  useEffect(() => {
    setPagination({ index: 0, entries: num_entriesSize });
  }, [merchantId]);

  function getDirection(type: any) {
    return type === "" ? "asc" : type === "descend" ? "desc" : "asc";
  }
  
  const columns = [
    {
      title: <FormattedMessage id="vendingmachines.table.column.serial" />,
      dataIndex: "serialNumber",
      render: (record: String, vendingMachines: VendingMachine) => (
        <Tooltip
          title={
            <span>
              <FormattedMessage id="vendingmachine.clearance.title" />
              {vendingMachines.unlocked ? (
                <FormattedMessage id="vendingmachine.clearance.value.positive" />
              ) : (
                <FormattedMessage id="vendingmachine.clearance.value.negative" />
              )}
            </span>
          }
        >
        <Badge color={vendingMachines.unlocked ? "#61f285" : "#f26161"} text={record} />
        </Tooltip>
      ),
      sorter: (a: any, b: any, type: any) => {
        setSort("serialNumber");
        setDirection(getDirection(type));
        return a.serialNumber?.localeCompare(b.serialNumber);
      }
    },
    {
      title: <FormattedMessage id="vendingmachines.table.column.type" />,
      dataIndex: "vendingMachineType",
      render: (record: VendingMachineType) =>
        record?.type && <Tag color={colorMap[record?.type]}>{record?.description}</Tag>,
      sorter: (a: any, b: any, type: any) => {
        setSort("vendingMachineType.description");
        setDirection(getDirection(type));
        return a.vendingMachineType?.description?.localeCompare(b.vendingMachineType?.description);
      }
    },
    {
      title: <FormattedMessage id="vendingmachines.table.column.merchant" />,
      dataIndex: "merchant",
      render: (record: Merchant) => record?.name,
      sorter: (a: any, b: any, type: any) => {
        setSort("merchant.name");
        setDirection(getDirection(type));
        return a.merchant?.name?.localeCompare(b.merchant?.name);
      }
    },
    {
      title: <FormattedMessage id="vendingmachines.table.column.name" />,
      dataIndex: "name",
      render: (record: String, vendingMachine: VendingMachine) => vendingMachine.vmName,
      sorter: (a: any, b: any, type: any) => {
        setSort("vmName");
        setDirection(getDirection(type));
        return a.vmName?.localeCompare(b.vmName);
      }
    },
    {
      title: <FormattedMessage id="vendingmachines.table.column.address" />,
      dataIndex: "address",
      render: (record: String, vendingMachine: VendingMachine) => (
        <span>
          {vendingMachine.postalcode ? vendingMachine.postalcode + "," : ""}{" "}
          {vendingMachine.city ? vendingMachine.city + "," : ""} {vendingMachine.address}
        </span>
      ),
      sorter: (a: any, b: any, type: any) => {
        setSort("postalcode");
        setDirection(getDirection(type));
        return a.postalcode?.localeCompare(b.postalcode);
      }
    },
    {
      title: <FormattedMessage id="vendingmachines.table.column.installation" />,
      dataIndex: "description",
      sorter: (a: any, b: any, type: any) => {
        setSort("description");
        setDirection(getDirection(type));
        return a.description?.localeCompare(b.description);
      }
    },
    {
      title: <FormattedMessage id="vendingmachines.table.column.actions" />,
      render: (text: string, record: VendingMachine, index: number) => (
        <>
          <Tooltip title="Edit">
            <Link to={record.serialNumber}>
              <Button icon={<EditOutlined />} type="text" />
            </Link>
          </Tooltip>
        </>
      ),
    },
  ];

  const onSelectChange = (selectedVendingmachines: any) => {
    setSelected(selectedVendingmachines);
  };

  async function downloadPdf() {
    const pdf = await queryCache.fetchQuery("vmPdf", () => api.vendingMachines.getListAsPdf(selected));

    const link = document.createElement("a");
    document.body.appendChild(link);
    link.style.display = "none";

    const blob = new Blob([pdf], { type: "application/zip" });
    const url = URL.createObjectURL(blob);

    link.href = url;
    link.download = "vendingmachines.zip";
    link.click();
  }

  const unlockSelected = async () => {
    console.log(selected);
    await api.vendingMachines.unlock(selected);

    api.vendingMachines
      .page(pagination.entries, pagination.index, merchantId, sort, direction, "")
      .then((res) => setData(res));
  };

  return (
    <>
      <PageHeader
        title={<FormattedMessage id="vendingmachines.title" />}
        extra={[
          isAdmin ? <MerchantSelect key={1} onChange={setMerchantId} value={merchantId} /> : <div></div>,
          showAddPOS ? (
            <Link to="new" key="1">
              <Button type="primary" icon={<PlusOutlined />}>
                <FormattedMessage id="vendingmachines.new-pos" />
              </Button>
            </Link>
          ) : (
            <div></div>
          ),
        ]}
      />
      <div style={{ margin: "16px 24px 0" }}>
        <Input
          placeholder={intl.formatMessage({ id: "common.search" })}
          defaultValue=""
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
        />
        <Table
          rowKey={(record: VendingMachine) => record.serialNumber}
          rowSelection={{
            type: "checkbox",
            onChange: (selectedRowKeys) => {
              onSelectChange(selectedRowKeys);
            },
            selections: [
              ...(isAdmin
                ? [
                    {
                      key: "unlock",
                      text: intl.formatMessage({ id: "vendingmachines.table.actions.unlock" }),
                      onSelect: () => unlockSelected(),
                    },
                    {
                      key: "Download PDF",
                      text: intl.formatMessage({ id: "vendingmachines.table.actions.download" }),
                      onSelect: () => downloadPdf(),
                    },
                  ]
                : isMerchantAdmin || KeycloakRoles()?.includes("ZIIB_Sachbearbeiter")
                ? [
                    {
                      key: "Download PDF",
                      text: intl.formatMessage({ id: "vendingmachines.table.actions.download" }),
                      onSelect: () => downloadPdf(),
                    },
                  ]
                : []),
            ],
          }}
          columns={columns}
          dataSource={data}
          pagination={false}
        />
        <Row justify="end">
          <b style={{ margin: 10 }}>
            {pagination.index * pagination.entries}-{pagination.index * pagination.entries + data.length}{" "}
            {intl.formatMessage({
              id: "antd.table.pagination.of",
            })}{" "}
            {pageSize}{" "}
            {intl.formatMessage({
              id: "antd.table.pagination.items",
            })}
          </b>

          <CustomPaginationNav
            index={pagination.index}
            pageSize={pageSize}
            index_entries_datalength={pagination.index * pagination.entries + data.length}
            entries={Number(sessionStorage.getItem("entriesSize")) || 10}
            onPrev={() => {
              setPagination({ index: pagination.index - 1, entries: pagination.entries });
              sessionStorage.setItem("pageIndex", `${pagination.index - 1}`);
            }}
            onNext={() => {
              setPagination({ index: pagination.index + 1, entries: pagination.entries });
              sessionStorage.setItem("pageIndex", `${pagination.index + 1}`);
            }}
            onSelect={(value) => {
              sessionStorage.setItem("entriesSize", `${value}`);
              setPagination({ index: pagination.index, entries: Number(value) });
            }}
          />
        </Row>
      </div>
    </>
  );
};

export default VendingMachines;
