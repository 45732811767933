/* eslint-disable react/style-prop-object */
import React from "react";
import { Table } from "antd";
import { useQuery } from "react-query";
import api from "../api";
import { FormattedMessage, FormattedNumber } from "react-intl";

const columns = [
  {
    title: <FormattedMessage id="transaction.history.table.posType" />,
    dataIndex: "key",
    render: (value: VendingMachine) => value,
    sorter: (a: any, b: any) => a?.key.localeCompare(b?.key),
  },
  {
    title: <FormattedMessage id="transaction.statistics.table.sales" />,
    dataIndex: "paymentCount",
    align: "right" as "right",
    sorter: (a: any, b: any) => a.paymentCount - b.paymentCount,
  },
  {
    title: <FormattedMessage id="transaction.history.table.revenue" />,
    align: "right" as "right",
    render: (value: any) => value.currency !== value.multiCurrency ? <FormattedMessage id="transaction.history.table.multipleCurrencies" /> : <FormattedNumber style="currency" currency={value.currency} value={value.sumAmount / 100} />,
    sorter: (a: any, b: any) => a.sumAmount - b.sumAmount,
  },
];

type StatisticPerPosTypeProps = {
  merchant: string;
  from?: string;
  until?: string;
  isAdmin?: boolean;
  currency?: string;
};
const StatisticPerPosType: React.FC<StatisticPerPosTypeProps> = ({ merchant, from, until, isAdmin, currency }) => {
  const { data } = useQuery(
    ["statistic-vmtype", merchant, from, until, currency],
    () =>
      isAdmin && (merchant === undefined || merchant === "")
        ? api.payments.findStatisticsVmTypesAll(from, until, currency)
        : api.payments.findStatisticVmTypesById(merchant , from, until, currency),
    {
      enabled: [merchant, from, until, currency],
    }
  );

  const getPaymentStatistics = () => {
    return data?.content ?? [];
  };

  return (
    <Table
      scroll={{ x: true }}
      title={() => <FormattedMessage id="statistics.table.vendingmachinetype" />}
      columns={columns}
      dataSource={getPaymentStatistics()}
      pagination={{ hideOnSinglePage: true }}
    />
  );
};

export default StatisticPerPosType;
